<template>
  <form-transformations />
</template>

<script>
  import FormTransformations from '@/components/forms/form-transformations'

  export default {
    name: 'create',
    components: { FormTransformations },
  }
</script>

<style scoped>

</style>
